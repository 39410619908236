@import "~sanitize.css";
@import "fonts";

$photo-count: 6;

@mixin narrow {
	@media screen and (max-width: 600px) {
		@content;
	}
}
@mixin wide {
	@media screen and (min-width: 1000px) {
		@content;
	}
}

@mixin page-section($v-margin: 0) {
	box-sizing: content-box;
	max-width: 1000px;
	margin: $v-margin auto;
	padding: 0 7vw;
}

body {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 200;
	font-size: 24px;
	padding: 60px 0 40vw 0;
	background: url('./img/crowd.jpg') no-repeat bottom center;
	background-size: 100vw;

	@include narrow {
		font-size: 20px;
	}
}

a {
	color: black;
	&:visited {
		color: #333;
	}
}

h1 {
	font-family: Caveat, sans-serif;
	font-size: 2em;
	margin-bottom: 16px;

	@include narrow {
		font-size: 8vw;
	}
}

.header {
	@include page-section;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	h1 {
		flex: 0 0 auto;
	}
	&__nav {
		flex: 1 1 auto;
		display: flex;
		flex-wrap: nowrap;
		align-items: stretch;
		justify-content: flex-end;
		margin-left: 16px;
	}
	&__nav-button {
		display: flex;
		border: 1px solid #999;
		padding: 4px;
		align-items: center;
		justify-content: center;
		margin: 0 4px;
		min-width: 34px;
		min-height: 34px;
		text-decoration: none;
		font-size: 0.75em;
		color: #666;

		&:visited {
			color: #666;
		}
		&:hover, &--active {
			border-color: black;
			color: black;
		}

		svg {
			display: block;
			width: 24px;
			height: 24px;
		}
		&--fb:hover {
			color: #3b5998;
		}

		@include narrow {
			min-width: 28px;
			min-height: 28px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
	&__nav-separator {
		width: 2px;
		background: #eee;
		margin: 0 8px;
	}
}

.essentials {
	@include page-section(20px);
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 40px;
	font-weight: 400;

	span {
		display: block;
		margin: 8px 16px;
	}
}

.blur {
	filter: blur(10px);
}

.info {
	@include page-section(40px);
	line-height: 1.3em;
}

.photos {
	display: flex;
	justify-content: center;

	&__thumb {
		display: block;
		background-size: cover;
		background-position: top center;
		border: 1px solid white;
		box-shadow: inset 0 0 1px 1px white;
		flex: 0 1 (100vw / $photo-count);
		height: (100vw / $photo-count);

		@include narrow {
			flex: 1 1 33vw;
			height: 33vw;
		}
	}

	@include narrow {
		flex-wrap: wrap;
	}
}

.howto {
	@include page-section(20px);
	display: flex;
	justify-content: stretch;
	flex-wrap: wrap;

	ul {
		flex: 300px 1 0;
		list-style-type: none;
		margin: 20px 0;
		padding: 0;

		@include narrow {
			font-size: 1.25em;
		}
		@include wide {
			font-size: 1.25em;
		}

		li {
			margin: 0.2em;
			padding-left: 1.4em;
			background-size: .9em .9em;
			background-position: 0 .2em;
			background-repeat: no-repeat;
		}
	}
	&__do li {
		background-image: url('./svg/do.svg');
	}
	&__dont li {
		background-image: url('./svg/dont.svg');
	}
}

.map {
	margin: 45px 0;
	position: relative;
	overflow: hidden;

	iframe {
		position: relative;
		display: block;
		width: 100%;
		height: 50vmin;
		min-height: 300px;
		border: 0;
		z-index: 100;
	}
	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -30px;
		right: -30px;
		box-shadow: inset 0 0 20px 10px white;
		z-index: 200;
		pointer-events: none;
	}
}

.footer {
	@include page-section;
	padding: 16px;
	text-align: center;
	font-size: 20px;

	&__copy {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 8px 0;
	}

	&__dude {
		margin-left: 4px;
		color: #999;
		transition: .3s color;
		cursor: zoom-in;

		&:hover {
			color: rgba(white, 0);
		}
		&--left:hover svg {
			transform: translateX(20px);
		}
		&--right:hover svg {
			transform: translateX(-20px);
		}

		svg {
			display: block;
			transform: translateX(0);
			transition: .3s transform;
		}
	}

	&__attrib {
		font-size: .66em;
	}
}

.templates {
	display: none;
}
