/* caveat-700 - latin-ext_latin */
@font-face {
	font-family: 'Caveat';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('/fonts/caveat-v9-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/fonts/caveat-v9-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-200 - latin-ext_latin_cyrillic */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 200;
	src: local(''),
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-200italic - latin-ext_latin_cyrillic */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 200;
	src: local(''),
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin-ext_latin_cyrillic */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('/fonts/source-sans-pro-v14-latin-ext_latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
